.App {
  text-align: center;
}

.MarktFenster {
  width: 80%;
  height: 50vh;
  overflow-y: scroll;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  padding: 10px;
  /* background-color: rgb(21, 18, 27); */
  border-radius: 15px;
  border: 5px solid #ffc82e;
}

.Markt {
  max-width: 35%;
  margin: 0;
  margin-left: auto;
  margin-right: 10%;
  margin-bottom: 15px;
  padding: 10px;
  background-color: #ffc82e;
  border-radius: 15px;
  border-bottom: 5px solid #ff0000;
}

.MarktFilter {
  position: fixed;
  max-width: 25%;
  margin: 0;
  margin-right: auto;
  margin-left: 10%;
  margin-bottom: 15px;
  padding: 10px;
  background-color: #dd291e;
  border-radius: 15px;
  border-bottom: 5px solid #002878;
}

.MarktFeld {
  margin: 0;
  color: #000000;
}
.MarktHead {
  margin: 0;

  margin-bottom: 25px;
  color: #ffc82e;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Kodak-logo {
  margin-left: 10%;
  margin-right: auto;
  width: 15%;
  margin-bottom: 2%;
  height: 15%;
}
.DM-logo {
  margin-left: auto;
  margin-right: 10%;
  width: 15%;
  height: 15%;
  margin-bottom: 5%;
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #ffc82e;
}

.App-link {
  color: #ffffff;
}
